<template>
  <div class="publish-device publish-need">
    <el-form ref="form" :model="form" :rules="rules">
      <Header />
      <div class="container">
        <div class="w-1200">
          <div class="publish-need-title">修改求租信息</div>
          <div class="base-step">
            <div class="step-item" :class="{ active: true }"><span class="num">1</span><span class="name">编写信息</span></div>
            <i class="divider" :class="{ active: false }"></i>
            <div class="step-item"><span class="num">2</span><span class="name">申请发布</span></div>
            <i class="divider"></i>
            <div class="step-item"><span class="num">3</span><span class="name">平台审核</span></div>
          </div>
          <section class="form">
            <div class="form-title">基本信息</div>
            <div class="form-main">
              <div class="form-item">
                <div class="form-label">选择分类</div>
                <el-form-item>
                  <el-cascader 
                  ref="cascader"
                  v-model="form.type_id" 
                  style="width:350px " 
                  :options="checkList" 
                  :props="{ checkStrictly: true,value:'type_id' , label:'type_name' }" 
                  clearable
                  @change="handleChange"
                  ></el-cascader>
                </el-form-item>
              </div>
              <div class="form-item">
                <div class="form-label">适用工况</div>
                <el-form-item prop="seekrent_type">
                  <el-checkbox-group v-model="form.seekrent_type">
                    <el-checkbox label="房建工程"></el-checkbox>
                    <el-checkbox label="市政工程"></el-checkbox>
                    <el-checkbox label="公路工程"></el-checkbox>
                    <el-checkbox label="水利工程"></el-checkbox>
                    <el-checkbox label="园林工程"></el-checkbox>
                    <el-checkbox label="桥隧工程"></el-checkbox>
                    <el-checkbox label="其他"></el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </div>
              <div class="form-item">
                <div class="form-label"><span class="icons">*</span>地址</div>
                <el-form-item prop="inputs.address">
                  <el-input id="test" v-model="form.address" placeholder="请输入地址…" style="width: 430px" @change="$refs.map.setPlace(form.address)"></el-input>
                  <div id="searchResultPanel" style="border: 1px solid #c0c0c0; width: 500px; height: auto; display: none"></div>
                </el-form-item>
                <Map class="map" style="height: 300px" ref="map" @getAddress="getAddress" :searchAddress="form.address"> </Map>
              </div>
              <div class="form-item">
                <div class="form-label">租赁时间</div>
                <el-form-item prop="rental_num">
                  <el-input type="number" v-model="form.rental_num" placeholder="请输入…" style="width: 100px; margin-right: 36px"></el-input>
                  <el-radio v-model="form.rental_type" label="1">按日</el-radio>
                  <el-radio v-model="form.rental_type" label="2">按月</el-radio>
                  <el-radio v-model="form.rental_type" label="3">按年</el-radio>
                </el-form-item>
              </div>
              <div class="form-item">
                <div class="form-label">设备品牌</div>
                <el-form-item prop="device_brand">
                  <el-input v-model="form.device_brand" placeholder="请输入品牌名称…" style="width: 430px"></el-input>
                </el-form-item>
              </div>
              <div class="form-item">
                <div class="form-label">标题名称</div>
                <el-form-item prop="name">
                  <el-input v-model="form.name" placeholder="请输入标题名称…" style="width: 430px"></el-input>
                </el-form-item>
              </div>
              <div class="form-item">
                <div class="form-label">求租内容</div>
                <el-form-item>
                  <!-- <div style="border: 1px solid #d3d3d3; height: 290px; border-radius: 4px; text-align: center; line-height: 290px"></div> -->
                  <my-editor class="editor" @gethtml="gethtml" :detail_info =this.form.seekrent_content :key="timer"></my-editor>
                </el-form-item>
              </div>
              <div class="form-item">
                <div class="form-label">图片</div>
                <el-form-item>
                  <el-upload action="http://114.67.170.224:21880/api/upload" list-type="picture-card" :on-success="handleAvatarSuccess" :on-change="change" :file-list="form.seekrent_img" :on-remove="handleRemove">
                    <i class="el-icon-plus"></i>
                    <div>添加上传图片</div>
                  </el-upload>
                </el-form-item>
              </div>
            </div>
          </section>
          <section class="form-btn-group">
            <el-form-item>
              <el-button class="btn-item btn-border" :plain="true" @click="$router.go(-1)">取消</el-button>
              <el-button class="btn-item" type="success" :plain="true" @click="goEnd">申请修改</el-button>
            </el-form-item>
          </section>
          <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
            <span class="back">提交成功,请选择返回页面</span>
            <span slot="footer" class="dialog-footer">
              <el-button class="backhome" type="success" @click="gohome">返回首页</el-button>
              <el-button class="backNeed" type="primary" @click="goMyDevice">我的需求</el-button>
            </span>
          </el-dialog>
        </div>
      </div>
      <Footer />
      <right-nav />
    </el-form>
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
import RightNav from '@/components/rightNav'
import { typeList } from '@/api/publish.js'
import kits from '@/kits/index.js'
import myEditor from '../publishNeed/myEditor.vue'
import {getupdateneed,updateNeed} from '@/api/myorder.js'
import Map from '@/components/map.vue'
export default {
  components: {
    Header,
    Footer,
    RightNav,
    myEditor,
    Map
  },
  data() {
    return {
      b1:'',
      radio: '',
      checkList: [],
      options: [],
      input: '',
      value: {},
      seekrent_imgs:[],
      seekrent_imgsa:[],
      dialogVisible: false,
      form: {
        type_id: null,
        seekrent_type: [],
        address: '',
        rental_num: null,
        rental_type: '',
        device_brand: '',
        name: '',
        // 求租内容
        seekrent_content: '',
        seekrent_img: []
      },
    
      rules: {
        seekrent_type: [{ type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }],
        address: [{ required: true, message: '请输入地址', trigger: 'blur' }],
        rental_num: [{ required: true, message: '请输入时间', trigger: 'blur' }],
        device_brand: [{ required: true, message: '请输入设备品牌', trigger: 'blur' }],
        name: [{ required: true, message: '请输入标题名称', trigger: 'blur' }]
      }
    }
  },
  computed: {},
created(){
  this.reviseDevice()
  this.getdata()
},
  mounted() {
    typeList()
      .then(res => {
        if (res.status != 200) return
        this.checkList = kits.arr.tree_set(res.data, 'parent_id', 'type_id')
        // console.log(this.checkList)
        for (let i = 0; i < this.checkList.length; i++) {
          for (let j = 0; j < this.checkList[i].children.length; j++) {
            for (let k = 0; k < this.checkList[i].children[j].children.length; k++) {
              this.options[i].push(this.checkList[i])
            }
          }
        }
      })
      .catch(err => {
        return err
      })
  },
  methods: {
    async reviseDevice(){
        let id = this.$route.query.id*1
        let res  = await getupdateneed({ inputs: {device_seekrent_id:id} })

        this.form = res.data

        this.form.rental_type = res.data.rental_type + ''
        let seekrent_imgs = JSON.parse(this.form.seekrent_img)
        this.form.seekrent_img = []
            for(let m =0; m<=seekrent_imgs.length-1;m++){
        this.form.seekrent_img.push({url:kits.img.url(seekrent_imgs[m])})
       }

       let time = this.form.seekrent_type.split(",")
       for(let i =0;i<=time.length-1;i++){
          if(time[i] == 1){
            time[i] = '房建工程' 
          }else if(time[i] == 2){
            time[i] = '市政工程'
          }else if(time[i] == 3){
            time[i] = '公路工程'
          }else if(time[i] == 4){
            time[i] = '水利工程'
          }else if(time[i] == 5){
            time[i] = '园林工程'
          }else if(time[i] == 6){
            time[i] = '桥隧工程'
          }else if(time[i] == 7){
            time[i] = '其他'
          }
       }
       this.form.seekrent_type = time

        this.timer = new Date().getTime()
    },

    async getdata() {
      let id = this.$route.query.id*1
      let res1  = await getupdateneed({ inputs: {device_seekrent_id:id} })

      res1.data.seekrent_img = JSON.parse(res1.data.seekrent_img);

      this.b1 = JSON.stringify(res1.data);

    },
    gethtml(html) {
      this.form.seekrent_content = html
    },
    getAddress(address) {
      this.form.address = address
    },
    handleRemove(file){
    let removefile1 = file
      let removearr1 = []
      removearr1.push(removefile1)
      for (let i = 0; i < removearr1.length; i++) {
        for (let j = 0; j <  this.form.seekrent_img.length; j++) {
          if (removearr1[i] ==  this.form.seekrent_img[j]) {
            this.form.seekrent_img.splice(j, 1)
          }
        }
      }
    },
    // 富文本
    onEditor() {},
    getList() {},
    handleAvatarSuccess(res) {
      this.form.seekrent_img.push({url: kits.img.url(res.data.value)})
    },
    // 取消
    cancel(form) {
      this.$refs[form].resetFields() //重置表单数据
    },
    // 申请成功
    async goEnd(){
      const confirm = await this.$confirm('是否确认信息无误, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(error => error)
      if (confirm !== 'confirm') {
        return this.$message.info('您已取消修改')
      }
      this.apply()
    },
    apply() {
      for (let i = 0; i <=this.form.seekrent_img.length - 1; i++) {
      let a =this.form.seekrent_img[i].url.split('http://114.67.170.224:21880/api/view?url=')
      this.seekrent_imgs.push(a[a.length - 1])
    }
       this.form.seekrent_img = this.seekrent_imgs
       for(let i =0;i<=this.form.seekrent_type.length-1;i++){
          if( this.form.seekrent_type[i] == '房建工程'){
            this.form.seekrent_type[i] =  1
          }else if(this.form.seekrent_type[i] == '市政工程'){
            this.form.seekrent_type[i] = 2
          }else if(this.form.seekrent_type[i] == '公路工程'){
            this.form.seekrent_type[i] = 3
          }else if(this.form.seekrent_type[i] == '水利工程'){
            this.form.seekrent_type[i] = 4
          }else if(this.form.seekrent_type[i] == '园林工程'){
            this.form.seekrent_type[i] = 5
          }else if(this.form.seekrent_type[i] == '桥隧工程'){
            this.form.seekrent_type[i] = 6
          }else if(this.form.seekrent_type[i] == '其他'){
            this.form.seekrent_type[i] = 7
          }
       }
       this.form.rental_type =  this.form.rental_type * 1
       let permission = this.form.seekrent_type.join(",");
       this.form.seekrent_type=permission

       if (JSON.stringify(this.form) === this.b1) {
        this.$message.warning("没有修改,请勿提交");
        setTimeout(function () {
          location.reload();
          window.scroll(0, 0);
        }, 1500);
      } else {
        updateNeed({inputs:this.form}).then(res => {
        this.form = res.data
        if (res.status !== 200) {
          this.$message.error('申请失败')
        }
      })
      this.$message.success('修改成功')
      this.$router.push({path:'/personalCenter/needManage'})
      }
       
      
      

    },
    searchResult1() {
            var ac = new window.BMapGL.Autocomplete({ input: "test" });
            console.log("ac", ac);
			let that = this
            ac.addEventListener("onhighlight", function (e) {
                //鼠标放在下拉列表上的事件
                var str = "";
                var _value = e.fromitem.value;
                var value = "";
                if (e.fromitem.index > -1) {
                    value =
                        _value.province +
                        _value.city +
                        _value.district +
                        _value.street +
                        _value.business;
                }
                str =
                    "FromItem<br />index = " +
                    e.fromitem.index +
                    "<br />value = " +
                    value;
                value = "";
                if (e.toitem.index > -1) {
                    _value = e.toitem.value;
                    value =
                        _value.province +
                        _value.city +
                        _value.district +
                        _value.street +
                        _value.business;
                }
                str +=
                    "<br />ToItem<br />index = " +
                    e.toitem.index +
                    "<br />value = " +
                    value;
                document.getElementById("searchResultPanel").innerHTML = str;
            });

            var myValue;
            ac.addEventListener("onconfirm", function (e) {
                //鼠标点击下拉列表后的事件
				document.getElementById("searchResultPanel").innerHTML = ''
                var _value = e.item.value;
                myValue =
                    _value.province +
                    _value.city +
                    _value.district +
                    _value.street +
                    _value.business;
                document.getElementById("searchResultPanel").innerHTML =
                    "onconfirm<br />index = " +
                    e.item.index +
                    "<br />myValue = " +
                    myValue;
				that.$refs.map.setPlace(myValue)
            });
        },
    // 返回首页
    gohome() {
      this.dialogVisible = false
      this.$router.push('/home')
    },
    // 返回我的设备
    goMyDevice() {
      this.dialogVisible = false
      this.$router.push('/personalCenter/needManage')
    },

    // 选择器变化事件
    handleChange() {
      let nodesInfo = this.$refs.cascader.getCheckedNodes()
      this.form.type_id = nodesInfo[0].data.type_id
    },
    // 

  }
}
</script>

<style scoped>
.backhome {
  margin-right: 150px;
}
.backNeed {
  margin-right: 30px;
}
.back {
  font-size: 26px;
}
.editor {
  /* height: 300px; */
}
</style>
